.pageWrapper {
  display: flex;
  flex-direction: column;
  padding-right: 10vw;
  padding-left: 10vw;
  padding-bottom: 5vh;
  padding-top: 5vh;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3rem;
}
.sidebar {
  display: flex;
  flex-direction: column;
  width: 20vw;
  padding: 0rem 1rem 1rem 1rem;
}
.main_content {
  display: flex;
  flex-direction: column;

  padding: 0rem 1rem 1rem 1rem;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tags {
  color: rgba(240, 150, 103, 1);
  color: white;

  background-color: rgba(240, 150, 103, 0.3);
  padding: 0.15rem;
  border-radius: 5px;
  font-size: 0.75rem;
}

.tags_container {
  display: flex;
  flex-direction: row;

  gap: 1rem;
}

.applyButton {
  margin-top: 3rem;
  padding: 0.3rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  color: #f09667;
  border: 1px solid rgba(240, 150, 103, 1);
}
.applyButton:hover {
  color: white;
  border: 1px solid white;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 3rem;
}

.markdown a {
  color: #f09667;
}

.markdown a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .sidebar {
    width: 100%;
    padding: 0;
  }
  .main_content {
    width: 100%;
    padding: 0;
    overflow-wrap: break-word;
    h3 {
      font-size: 2rem;
    }
  }
}
