.navbar {
  display: flex;
  width: 100vw;
  border-bottom: 2px solid white;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  margin: 0.5rem;
  img {
    object-fit: contain;
    width: 50px;
    height: 50px;

    &:hover {
      cursor: pointer;
    }
  }
}

.menuContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  width: 95%;
}
