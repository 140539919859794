.container {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.box {
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 40px;
  border: 1px solid #dddddd;
}

.base {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
}
.item2 {
  grid-column: 2 / span 2;
  text-align: center;
}
.item3 {
  grid-column: 4 / span 1;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .item2 {
    p {
      font-size: 14px;
    }
  }
}
