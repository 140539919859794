@import 'src/styles/colors.scss';

.darkBG {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // nested modal support
  z-index: 4;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $background-color;
  color: $primary;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: $background-color;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.headingBox {
  display: flex;
  width: 100%;
  text-align: center;
  h2 {
    margin: 0;
    padding: 10px;
    width: 100%;
    color: $primary;
    font-weight: 500;
    font-size: 18px;
  }
}

.modalContent {
  padding: 10px;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  cursor: pointer;
  padding: 4px 8px;
  border: none;
}
