/* Global styles */
// @import-normalize;
@import './colors.scss';
@import './typography.scss';

html,
body {
  min-height: 100vh;
  // height: auto;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: $primary;
  background: $background-color;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}
