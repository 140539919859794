@import 'styles/text.scss';

.inputFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inputPrefix {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 14px;
  border: 1px solid $input-border;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: $input-border;
  color: $primary;
}
