.logosContainer {
  display: inline-block;
  width: 40%;
  align-items: center;
}

.logosWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.wrapper {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid;
}

.logoItem {
  margin-bottom: 0.25rem;
  margin: 0 1rem 0 1rem;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .logosContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
  }
}
