.pageWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.loginWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login {
  width: 350px;
}

@media screen and (max-width: 451px) {
  .contentWrapper {
    h3 {
      font-size: 1.75em;
    }
  }
}
