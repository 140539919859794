.content {
  width: 70vw;
  margin-bottom: 10vh;
}

.chart_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.grid_item {
  display: flex;
  flex-direction: column;
  text-justify: center;
}

.text_center {
  text-align: center;
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .content {
    width: 100vw;
  }
  .chart_grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
