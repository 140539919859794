.submitButton {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1em;
  margin-bottom: 1em;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  input {
    box-sizing: border-box;
  }
}
