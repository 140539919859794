.registerContent {
  display: flex;
  width: 35%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 1rem;
  }
}

.clickableSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .registerContent {
    width: 100%;
  }
}
