.carouselContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.carousel {
  overflow: hidden;
  height: 100%;
  min-height: 350px;
}

.headerButton {
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.inner {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  transition: transform 0.3s;
  min-width: 0;
}

.carouselItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
  max-height: inherit;
}
.carouselButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    vertical-align: middle;
  }
  button {
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .carouselButtonsContainer {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
