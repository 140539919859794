.registerPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
}

.registerForm {
  display: flex;
  justify-content: center;
  width: 100%;
  input {
    min-width: 30ch;
  }
}

@media screen and (max-width: 768px) {
  .registerPage {
    h1 {
      font-size: 3.157rem;
    }
  }
}
