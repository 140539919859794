@import 'styles/colors.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  position: absolute;
  left: 10px;
  top: 14px;
  transition: all 0.2s;
  padding: 0 2px;
  z-index: 1;
  color: $inactive-label-color;
  font-size: 15px;
  pointer-events: none;
}

.styledLabel {
  position: absolute;
  top: -10px;
  color: $active-label-color;
  font-size: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: $primary !important;
  // HACK: To be able to change the background-color of the autofill from
  // chrome we have to have a transition on the background-color. Set the
  // transition time to be 5-6 days before it gets white.
  transition: background-color 500000s ease-in-out 0s;
  ~ label {
    top: -10px;
    color: $active-label-color;
    font-size: 14px;
  }
}

.text {
  padding: 0.8rem;
  border: 2px solid $input-border;
  background: $input-background;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  transition: border 0.3s;
  color: $primary;
}

.errorMsg {
  @extend .text;
  border: 2px solid $error;
}

.errorMsg::before {
  background: $error;
}

.errorMsg:focus {
  border: 2px solid $error;
}

.label::before {
  content: '';
  height: 5px;
  background: $input-background;
  position: absolute;
  left: 0px;
  top: 10px;
  width: 100%;
  z-index: -1;
}

.text:focus {
  border: 2px solid $active-label-color;
}

.text::placeholder {
  font-size: 16px;
  opacity: 0;
  transition: all 0.3s;
}
.text:focus::placeholder {
  opacity: 1;
}

.errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: $error;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: inherit;
  box-sizing: border-box;
  p {
    margin: 0.25rem 0 0 1rem;
  }
}

@media screen and (max-width: 750px) {
  .container {
    display: flex;
    justify-content: center;
    input,
    textarea {
      // override user input on smaller devices
      min-width: 0px !important;
    }
  }
  .inputContainer {
    align-items: stretch;
  }
  .errors {
    font-size: 12px;
  }
}
