@import 'styles/colors.scss';

.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1337;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.menu-item {
  // Todo: Fix design for these
  font-size: 3rem;
  color: $off-primary;
  list-style: none;
  text-align: left;
  border-bottom: 2px solid $off-primary;
  a {
    text-decoration: none;
    color: inherit;

    &:visited {
      color: inherit;
    }
  }

  &:hover {
    color: $primary;
    border-color: $primary;
  }
}

.menu-icon {
  z-index: 1338;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .menu-item {
    font-size: 2rem;
  }
}
