$td-red: #a4161a;
$secondary: #f8d2cc;

$background-color: #161926;
$off-background-color: #161a1d;

$active-label-color: #7e4ccb;
$inactive-label-color: #b3b3b3;

$input-background: #272530;
$input-border: #2f2c45;

$off-primary: #444658;
$primary: #ffffff;

$success: #5cb85c;
$error: #d9534f;
$info: #5bc0de;
$warning: #f0ad4e;
