.headerContainer {
  margin-top: 2em;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.headerWrapper {
  width: 100%;
  align-items: center;
  justify-content: center;
}

img {
  object-fit: scale-down;
}

.imageContainer {
  display: flex;
  justify-content: center;
  height: 20vh;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  margin-top: 1em;
  justify-content: center;
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1223px) {
  img {
    object-fit: scale-down;
    width: 90%;
  }

  .buttonWrapper {
    text-align: center;
    align-items: flex-start;
  }
}
