@import 'src/styles/colors';

.container {
  display: flex;
  text-align: center;
  align-items: center;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  text-align: center;
  align-items: center;
  input {
    min-width: 10%;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  text-align: center;
  outline: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $active-label-color;
}

input:focus + .slider {
  background-color: 0 0 1px $active-label-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.textContainer {
  text-align: center;
  margin-left: 0.5em;
}

.text {
  margin-right: 0.5em;
  white-space: nowrap;
  margin-bottom: 0;
}
