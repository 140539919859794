.base {
  display: flex;
  background-color: #2b2c3d;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  max-width: 90vw;
  justify-content: center;

  img {
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;
  }
}
