.header {
  min-height: 35vh;
  border-radius: 20px 20px 0px 0px;
}

.adminButtons {
  margin: 1% 0 1% 0;
}

@media screen and (max-width: 768px) {
  .header {
    max-width: 95vw;
  }
}
