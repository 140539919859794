@import '../../../styles/colors.scss';

.icon {
  display: flex;
  align-items: center;
  color: #f09667;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: transparent;
}

.icon:hover {
  color: rgba(240, 150, 103, 0.5);
  cursor: pointer;
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 1rem;
}

.filterTagWrapper {
  justify-content: flex-start;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.chip {
  color: white;
  padding: 0.2rem;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
  min-width: 3rem;
  font-size: 0.5rem;
  cursor: pointer;
}
.filterSortWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

hr {
  width: 100%;
}

.companyButton {
  cursor: pointer;
  font-size: 0.8rem;
  color: rgba(240, 150, 103, 0.5);
}

.companyButton::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgba(240, 150, 103, 0.5);
  transition: width 0.3s;
}

.companyButton:hover::after {
  width: 100%;
}

@mixin smallerDevices {
  .filterWrapper {
    width: 100%;
  }
}

.fade_in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1001px) {
  @include smallerDevices;
}

@media screen and (max-width: 768px) {
  @include smallerDevices;
}
