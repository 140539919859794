.general {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  width: 100%;
}
